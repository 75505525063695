<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="1200px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="200px"
      >
        <el-form-item label="年度培训计划名称" prop="trainingName">
          <el-input
            v-model="airForm.trainingName"
            placeholder="请输入年度培训计划名称"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="起草人" prop="editor">
          <el-input
            v-model="airForm.editor"
            placeholder="请输入起草人"
            type="textarea"
            clearable
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item label="起草日期" prop="editorTime">
          <el-date-picker
            v-model="airForm.editorTime"
            type="datetime"
            placeholder="请输入起草日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            :readonly="handleType !== 'add' && handleType !== 'update'"
          />
        </el-form-item>
        <el-form-item
          label="批准"
          v-if="handleType === 'update' && !airForm.approver"
        >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConform(airForm.id, 1)"
          >
            批准
          </el-button>
        </el-form-item>
        <el-form-item label="批准信息" v-if="airForm.approver">
          <div style="margin: 0">
            <span>批准人:{{ airForm.approver }}</span>
            <span> 批准时间:{{ airForm.approveTime }}</span>
          </div>
        </el-form-item>
        <el-form-item
          label="审核"
          v-if="handleType === 'update' && !airForm.reviewer"
        >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleConform(airForm.id, 2)"
          >
            审核
          </el-button>
        </el-form-item>
        <el-form-item label="审核信息" v-if="airForm.reviewer">
          <p style="margin: 0">
            <span>审核人:{{ airForm.reviewer }}</span>
            <span> 审核时间:{{ airForm.reviewTime }}</span>
          </p>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
      <section v-if="handleType != 'add'">
        <el-button
          type="primary"
          @click="handleAdd2"
          v-if="handleType === 'update'"
        >
          新 增
        </el-button>
        <el-table :data="airForm.planDetailList" style="width: 100%">
          <el-table-column prop="courseType" label="课程类型" align="center">
            <template slot-scope="scope">
              {{
                scope.row.courseType === 1
                  ? "专业外训"
                  : scope.row.courseType === 2
                  ? "部门内训"
                  : "公司层内训"
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="trainingDate"
            label="培训日期(年/月)"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="dept" label="部门/科室/车间" align="center">
          </el-table-column>
          <el-table-column prop="course" label="培训课程" align="center">
          </el-table-column>
          <el-table-column prop="participant" label="参训对象" align="center">
          </el-table-column>
          <el-table-column prop="trainer" label="培训师" align="center">
          </el-table-column>
          <el-table-column prop="classHour" label="课 时" align="center">
          </el-table-column>
          <el-table-column
            prop="evaluationMode"
            label="考核方式"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="trainingAgency"
            label="培训机构（专业外训填写）"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="budget"
            label="培训预算（专业外训填写）"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="remarks" label="备注" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                icon="el-icon-delete"
                size="small"
                @click.stop="
                  handleDelete2(scope.$index, airForm.planDetailList)
                "
                v-if="handleType === 'update'"
              >
                删除
              </el-button>
              <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                @click.stop="handleUpdate2(scope.$index, scope.row)"
                v-if="handleType === 'update'"
              >
                修改
              </el-button>
              <el-button
                type="text"
                icon="el-icon-info"
                size="small"
                @click.stop="handleInfo2(scope.$index, scope.row)"
              >
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div slot="footer">
          <el-button @click="airDialogVisible = false">关闭</el-button>
        </div> -->
      </section>
      <el-dialog
        width="60%"
        title="新增培训记录"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <el-form-item label="课程类型" prop="courseType">
            <el-select
              v-model="airDetailForm.courseType"
              placeholder="请选择课程类型"
              clearable
              filterable
              :disabled="airDetailForm.inputType === 'detail'"
            >
              <el-option key="1" label="专业外训" :value="1" />
              <el-option key="2" label="部门内训" :value="2" />
              <el-option key="3" label="公司层内训" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="培训日期" prop="trainingDate">
            <el-date-picker
              v-model="airDetailForm.trainingDate"
              type="datetime"
              placeholder="请输入培训日期"
              value-format="yyyy-MM-dd hh:mm:ss"
              :disabled="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="部门/科室/车间" prop="dept">
            <el-input
              v-model="airDetailForm.dept"
              placeholder="请输入部门/科室/车间"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="课程" prop="course">
            <el-input
              v-model="airDetailForm.course"
              placeholder="请输入课程"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="参训对象" prop="participant">
            <el-input
              v-model="airDetailForm.participant"
              placeholder="请输入参训对象"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="培训师" prop="trainer">
            <el-input
              v-model="airDetailForm.trainer"
              placeholder="请输入培训师"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="课时" prop="classHour">
            <el-input
              v-model="airDetailForm.classHour"
              placeholder="请输入课时"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="考核方式" prop="evaluationMode">
            <el-input
              v-model="airDetailForm.evaluationMode"
              placeholder="请输入考核方式"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="培训机构" prop="trainingAgency">
            <el-input
              v-model="airDetailForm.trainingAgency"
              placeholder="请输入培训机构"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="培训预算" prop="budget">
            <el-input
              v-model="airDetailForm.budget"
              placeholder="请输入培训预算"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input
              v-model="airDetailForm.remarks"
              placeholder="请输入备注"
              type="textarea"
              clearable
              :readonly="airDetailForm.inputType === 'detail'"
            />
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit2">
            确 定
          </el-button>
        </div>
      </el-dialog>
      <el-dialog
        width="60%"
        title="上级审批"
        :visible.sync="innerVisible2"
        :close-on-click-modal="false"
        append-to-body
      >
        <el-form
          ref="productFormRef"
          :model="airDetailForm"
          :rules="productFormRules"
          label-position="right"
          label-width="150px"
        >
          <section v-if="checkType == 1">
            <el-form-item label="批准人" prop="approver">
              <el-input
                v-model="airDetailForm.approver"
                placeholder="请输入批准人"
                type="input"
                clearable
                :readonly="airDetailForm.inputType === 'detail'"
              />
            </el-form-item>
            <el-form-item label="批准时间" prop="approveTime">
              <el-date-picker
                v-model="airDetailForm.approveTime"
                type="datetime"
                placeholder="请输入批准时间"
                value-format="yyyy-MM-dd hh:mm:ss"
                :disabled="airDetailForm.inputType === 'detail'"
              />
            </el-form-item>
          </section>
          <section v-if="checkType == 2">
            <el-form-item label="审核人" prop="reviewer">
              <el-input
                v-model="airDetailForm.reviewer"
                placeholder="请输入审核人"
                type="input"
                clearable
                :readonly="airDetailForm.inputType === 'detail'"
              />
            </el-form-item>
            <el-form-item label="审核时间" prop="reviewTime">
              <el-date-picker
                v-model="airDetailForm.reviewTime"
                type="datetime"
                placeholder="请输入审核时间"
                value-format="yyyy-MM-dd hh:mm:ss"
                :disabled="airDetailForm.inputType === 'detail'"
              />
            </el-form-item>
          </section>
        </el-form>
        <div slot="footer">
          <el-button @click="innerVisible2 = false"> 取 消 </el-button>
          <el-button type="primary" @click="productFormSubmit3">
            确 定
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="年度培训计划名称">
        <el-input
          v-model="searchForm.trainingName"
          placeholder="请输入年度培训计划名称"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="airList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="trainingName" label="年度培训计划名称" />
      <el-table-column prop="editorTime" label="创建时间" />
      <el-table-column prop="editor" label="创建人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="airList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getAnnualList,
  deleteAnnualList,
  approveAnnualList,
  reviewAnnualList,
  addAnnualList,
  addAnnualInfoDetail,
  getAnnualDetail,
  updateAnnualDetail,
} from "@/api/humanManagement/humanManagement.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      innerVisible2: false,
      airFormTitle: "",
      checkType: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      airList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules2: {
        trainingName: [
          {
            required: true,
            message: "年度培训计划名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        editor: [
          {
            required: true,
            message: "起草人不能为空",
            trigger: ["blur", "change"],
          },
        ],
        editorTime: [
          {
            required: true,
            message: "起草日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      productFormRules: {
        courseType: [
          {
            required: true,
            message: "课程类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        trainingDate: [
          {
            required: true,
            message: "培训日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        dept: [
          {
            required: true,
            message: "部门/科室/车间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        course: [
          {
            required: true,
            message: "课程不能为空",
            trigger: ["blur", "change"],
          },
        ],
        participant: [
          {
            required: true,
            message: "参训对象不能为空",
            trigger: ["blur", "change"],
          },
        ],
        trainer: [
          {
            required: true,
            message: "培训师不能为空",
            trigger: ["blur", "change"],
          },
        ],
        classHour: [
          {
            required: true,
            message: "课时不能为空",
            trigger: ["blur", "change"],
          },
        ],
        evaluationMode: [
          {
            required: true,
            message: "考核方式不能为空",
            trigger: ["blur", "change"],
          },
        ],
        trainingAgency: [
          {
            required: true,
            message: "培训机构不能为空",
            trigger: ["blur", "change"],
          },
        ],
        budget: [
          {
            required: true,
            message: "培训预算不能为空",
            trigger: ["blur", "change"],
          },
        ],
        reviewTime: [
          {
            required: true,
            message: "审核时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        reviewer: [
          {
            required: true,
            message: "审核人不能为空",
            trigger: ["blur", "change"],
          },
        ],
        approveTime: [
          {
            required: true,
            message: "审核时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        approver: [
          {
            required: true,
            message: "审核人不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let airList = await getAnnualList(params);
    this.airList = airList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addAnnualList(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            params.planDetailJson = JSON.stringify(params.planDetailList);
            delete params.planDetailList;
            delete params.workshopAuditor;
            delete params.workshopReviewTime;
            delete params.qualityAuditor;
            delete params.qualityReviewTime;
            delete params.status;
            delete params.workshopReviewResult;
            delete params.qualityReviewResult;
            await updateAnnualDetail(params);
          }
          this.airList = await getAnnualList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        // this.airDetailForm.reviewId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let params = {
              id: this.airForm.id,
              planDetailJson: JSON.stringify(this.airDetailForm),
            };
            // console.log(this.airDetailForm, "this.airDetailForm");
            console.log(params, "this.airDetailForm");
            let data = await addAnnualInfoDetail(params);
            let list = this.airForm.planDetailList;
            list.push(data[0]);
            this.airForm.planDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.planDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            let index = this.airDetailForm.index;
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            console.log(list, "list");
            console.log(this.airDetailForm, "this.airDetailForm");
            list.splice(index, 1, this.airDetailForm);
            this.airForm.planDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    productFormSubmit3() {
      this.$refs.productFormRef.validate(async (valid) => {
        console.log(this.airDetailForm);
        if (valid) {
          if (this.checkType == 1) {
            await approveAnnualList(this.airDetailForm);
            getAnnualDetail(this.airDetailForm.id).then((res) => {
              this.airForm = { ...res };
            });
            this.innerVisible2 = false;
          }
          if (this.checkType == 2) {
            await reviewAnnualList(this.airDetailForm);
            getAnnualDetail(this.airDetailForm.id).then((res) => {
              this.airForm = { ...res };
            });
            this.innerVisible2 = false;
          }
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增年度培训计划";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleAdd2() {
      this.handleDetailType = "add";
      this.innerVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteAnnualList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.airList = await getAnnualList(this.searchForm);
      });
    },
    handleDelete2(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        console.log(row, "row");
        console.log(index, "index");
        row.splice(index, 1);
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "年度培训计划";
      this.handleType = "update";
      getAnnualDetail(row.id).then((res) => {
        console.log(res);
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "年度培训计划";
      this.handleType = "detail";
      getAnnualDetail(row.id).then((res) => {
        this.airForm = { ...res };
        console.log(this.airForm);
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getAnnualList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.airList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getAnnualList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getAnnualList(this.searchForm).then((res) => {
        this.airList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleDetailType = "detail";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.inputType = "detail";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleUpdate2(index, row) {
      this.handleDetailType = "update";
      let airDetailForm = this.airDetailForm;
      airDetailForm = { ...row };
      airDetailForm.index = index;
      airDetailForm.inputType = "update";
      this.airDetailForm = airDetailForm;
      this.innerVisible = true;
    },
    handleConform(id, checkType) {
      this.innerVisible2 = true;
      this.checkType = checkType;
      this.airDetailForm.id = id;
      // const h = this.$createElement;
      // this.$msgbox({
      //   title: "审核",
      //   closeOnClickModal: false,
      //   message: h("p", { style: "text-align: center" }, [
      //     h("p", { style: "margin-bottom: 15px" }, "你确定进行审核？"),
      //     // h(
      //     //   "el-button",
      //     //   {
      //     //     on: {
      //     //       click: () => this.handleWorkshopReview(id, 2),
      //     //     },
      //     //   },
      //     //   "不通过"
      //     // ),
      //     h(
      //       "el-button",
      //       {
      //         attrs: { type: "primary" },
      //         on: {
      //           click: () => this.handleWorkshopReview(id, 1),
      //         },
      //       },
      //       "通过"
      //     ),
      //   ]),
      //   showCancelButton: false,
      //   showConfirmButton: false,
      // }).then((action) => {
      //   this.$message({
      //     type: "info",
      //     message: "action: " + action,
      //   });
      // });
    },
    async handleWorkshopReview(id, workshopReviewResult) {
      console.log(id);
      console.log(workshopReviewResult);
      let params = {
        id: id,
        // workshopReviewResult: workshopReviewResult,
      };
      await reviewAnnualList(params);
      getAnnualDetail(id).then((res) => {
        this.airForm = { ...res };
        this.$msgbox.close();
      });
    },
    handleConform2(id) {
      const h = this.$createElement;
      this.$msgbox({
        title: "批准",
        closeOnClickModal: false,
        message: h("p", { style: "text-align: center" }, [
          h("p", { style: "margin-bottom: 15px" }, "你确定批准该年度计划？"),
          // h(
          //   "el-button",
          //   {
          //     on: {
          //       click: () => this.handleQualityReview(id, 2),
          //     },
          //   },
          //   "不通过"
          // ),
          h(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: () => this.handleQualityReview(id, 1),
              },
            },
            "通过"
          ),
        ]),
        showCancelButton: false,
        showConfirmButton: false,
      }).then((action) => {
        this.$message({
          type: "info",
          message: "action: " + action,
        });
      });
    },
    async handleQualityReview(id, workshopReviewResult) {
      console.log(id);
      console.log(workshopReviewResult);
      let params = {
        id: id,
        approver: 123,
        approveTime: "2021-05-08 15:00:00",
        // workshopReviewResult: workshopReviewResult,
      };
      await approveAnnualList(params);
      getAnnualDetail(id).then((res) => {
        this.airForm = { ...res };
        this.$msgbox.close();
      });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.startupTime = val ? val[0] : "";
      this.airForm.offTime = val ? val[1] : "";
    },
    airDialogVisible(val) {
      if (!val) {
        this.airForm = {};
        this.runningTime = [];
      }
    },
    innerVisible(val) {
      if (!val) {
        this.airDetailForm = {};
      }
    },
    innerVisible2(val) {
      if (!val) {
        this.airDetailForm = {};
      }
    },
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
